import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { IsNullOrEmptyArray, IsStringNullEmptyOrUndefined } from '../utils/null-check';
import { SideBarMenuEntity, SidebarSubMenuEntity } from '../interface';
import { ActionEntityEnum, StorageKeyEnum } from '../utils/enum';
import { AssignedModuleData, UserInfoEntity } from '../interface/sign-in';
import { allFeaturedModules } from '../utils/constants';

declare var $: any;

function SideBar() {

    const navigate = useNavigate();

    const allMenus: SideBarMenuEntity[] = [
        {
            icon: "icon-dashboard menu-icon",
            moduleName: allFeaturedModules.dashboard,
            isHoverOpen: false,
            url: "/dashboard",
            subMenu: [],
        },
        {
            icon: "icon-newOrder menu-icon",
            moduleName: allFeaturedModules.wallOrders,
            isHoverOpen: false,
            url: "/wall-orders",
            subMenu: []
        },
        {
            icon: "icon-setting menu-icon",
            moduleName: allFeaturedModules.settings,
            isHoverOpen: false,
            url: "/settings",
            subMenu: []
        },
        {
            icon: "icon-download menu-icon",
            moduleName: allFeaturedModules.downloads,
            isHoverOpen: false,
            url: "/downloads",
            subMenu: []
        },
        {
            icon: "icon-analytics menu-icon",
            moduleName: allFeaturedModules.analytics,
            isHoverOpen: false,
            url: "/analytics",
            subMenu: [
                // { moduleName: allFeaturedModules.reports, url: "/analytics/reports" },
                // { moduleName: allFeaturedModules.orderReport, url: "/analytics/order-report" },
            ]
        },
        {
            icon: "icon-role menu-icon",
            moduleName: "Role Management",
            isHoverOpen: false,
            url: "/role-management",
            subMenu: [],
        },
        {
            icon: "icon-newOrder menu-icon",
            moduleName: allFeaturedModules.glassOrders,
            isHoverOpen: false,
            url: "/glass-orders",
            subMenu: []
        },
        {
            icon: "icon-transaction menu-icon",
            moduleName: allFeaturedModules.transactions,
            isHoverOpen: false,
            url: "",
            subMenu: [
                {
                    moduleName: "Make Payment",
                    url: "/transactions/payment"
                },
                {
                    moduleName: "Payment History",
                    url: "/transactions/payment-history"
                },
                {
                    moduleName: "Settlements",
                    url: "/transactions/settlements"
                },
                {
                    moduleName: "Draft Transactions",
                    url: "/transactions/draft-transactions"
                }
            ]
        },
        {
            icon: "icon-order-due menu-icon",
            moduleName: allFeaturedModules.orderDue,
            isHoverOpen: false,
            url: "/order-due",
            subMenu: []
        },

    ];

    const [sidebarMenus, setSidebarMenus] = useState<SideBarMenuEntity[] | []>([]);

    useEffect(() => {
        const userStr: string | null = localStorage.getItem(StorageKeyEnum.JB_GLASS_USER_INFO);

        if (userStr && !IsStringNullEmptyOrUndefined(userStr)) {
            const userInfo: UserInfoEntity = JSON.parse(userStr);
            const modules: AssignedModuleData[] = userInfo.assignedModule;

            const listActionModules = modules.filter(e => e.action === ActionEntityEnum.LIST && IsStringNullEmptyOrUndefined(e.parentId));
            console.log('listActionModules', listActionModules);
            const listActionSubModules = modules.filter(e => e.action === ActionEntityEnum.LIST && !IsStringNullEmptyOrUndefined(e.parentId));

            let authorizedMenus: SideBarMenuEntity[] = [];

            for (const list of listActionModules) {
                const index: number = allMenus.findIndex(e => e.moduleName === list.moduleName && list.isActive);

                if (index !== -1) {
                    const menu: SideBarMenuEntity = allMenus[index];
                    authorizedMenus.push({ ...menu, moduleId: list.moduleId, subMenu: [] });
                }
            }
            // authorizedMenus = authorizedMenus.sort((a, b) => a.moduleName.toLowerCase() > b.moduleName.toLowerCase() ? 1 : -1);
            authorizedMenus = authorizedMenus.filter(e => (e.moduleName).toLowerCase() === 'dashboard')
                .concat(authorizedMenus.filter(e => (e.moduleName).toLowerCase() !== 'dashboard')
                    .sort((a, b) => (a.moduleName).toLowerCase().localeCompare((b.moduleName).toLowerCase())));

            for (const item of listActionSubModules) {
                const parentMenuIndex: number = authorizedMenus.findIndex(e => e.moduleId === item.parentId);
                if (parentMenuIndex !== -1) {
                    const authorizedParentMenu = authorizedMenus[parentMenuIndex];

                    const menuWithSubmenu: SideBarMenuEntity | undefined = allMenus.find(e => e.moduleName === authorizedParentMenu.moduleName);
                    if (menuWithSubmenu) {

                        const index: number = menuWithSubmenu.subMenu.findIndex(e => e.moduleName === item.moduleName)
                        if (index !== -1) {
                            (authorizedParentMenu.subMenu as SidebarSubMenuEntity[]).push({
                                moduleName: menuWithSubmenu.subMenu[index].moduleName,
                                url: menuWithSubmenu.subMenu[index].url,
                            })
                        }
                    }
                }
            }

            setSidebarMenus(authorizedMenus);
            console.log(authorizedMenus, 'authorizedMenus');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        $('[data-bs-toggle="minimize"]').on("click", function () {
            const body = $('body');

            if (body) {
                if ((body.hasClass('sidebar-toggle-display')) || (body.hasClass('sidebar-absolute'))) {
                    body.toggleClass('sidebar-hidden');
                } else {
                    body.toggleClass('sidebar-icon-only');
                }
            }
        });
    }, []);

    const onMouseEnter = (index: number) => {
        const sideBar = [...sidebarMenus]
        sideBar[index].isHoverOpen = true;
        setSidebarMenus(sideBar);
    }

    const onMouseLeave = (index: number) => {
        const sideBar = [...sidebarMenus]
        sideBar[index].isHoverOpen = false;
        setSidebarMenus(sideBar);
    }

    console.log(sidebarMenus,'sidebarMenus')
    return (
        <nav className="sidebar sidebar-offcanvas" id="sidebar">
            <ul className="nav">
                {
                    sidebarMenus.map((value: SideBarMenuEntity, index: number) => (
                        <li onMouseEnter={() => onMouseEnter(index)}
                            onMouseLeave={() => onMouseLeave(index)}
                            className={`nav-item ${!value.isHoverOpen ? '' : 'hover-open'}`}
                            key={index}
                        >
                            {
                                IsNullOrEmptyArray(value.subMenu)
                                    ?
                                    <span className="nav-link cursor-pointer" onClick={() => navigate(value.url)}>
                                        <i className={value.icon}></i>
                                        <span className="menu-title">{value.moduleName}</span>
                                    </span>
                                    :
                                    <>
                                        <a className="nav-link" data-bs-toggle="collapse" href={"#ui-basic-" + index} aria-expanded="false" aria-controls={"ui-basic-" + index}>
                                            <i className={value.icon}></i>
                                            <span className="menu-title">{value.moduleName}</span>
                                            <i className="menu-arrow"></i>
                                        </a>

                                        <div className="collapse" id={"ui-basic-" + index}>
                                            <ul className="nav flex-column sub-menu mb-2">
                                                {
                                                    value.subMenu.map((sm: SidebarSubMenuEntity, jIndex: number) => (
                                                        <li className="nav-item" key={jIndex}>
                                                            <Link className="nav-link" to={sm.url}>
                                                                {sm.moduleName}
                                                            </Link>
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </>
                            }
                        </li>
                    ))
                }
            </ul>
        </nav>
    )
}

export default SideBar;